import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from "@apollo/client/core";
import { RetryLink } from "@apollo/client/link/retry";
import { cookies } from "@dayforward/core/utils";
import { inject } from "vue";

// header consts
const RequestIdHeader = "X-Request-ID";
const SessionIdHeader = "X-DF-Session-ID";
const sendSessionID = false;

// injection key const
export const ApolloClientKey = "apolloClientKey";

export class ApolloConfig {
  constructor(options) {
    // Sets up the Apollo link / interceptor objects that inject rules and
    // functionality into the standard request / response chain
    this.link = options?.link ?? this.initializeLink(options);
    // Sets up the Apollo cache to be an in memory only cache.
    this.cache = options?.cache ?? new InMemoryCache();
    this.client = new ApolloClient({
      cache: this.cache,
      link: this.link,
      connectToDevTools: options?.connectToDevTools ?? false,
    });
  }

  initializeLink(options) {
    // This link attaches / verifies contextual info + headers for the request
    // and response. It also does a little bit of logging.
    const context = new ApolloLink(this.processContextStep(options));
    // This link is for the base http configuration
    const http = new HttpLink({ uri: process.env.VUE_APP_API_URL });
    // This link is used to retry when network errors occur. Note: It won't
    // retry when a graphql error occurs (i.e.: bad/malformed request, wrong
    // params, etc). We use the standard options.
    const retry = new RetryLink();
    // Combines all the links into a single link object
    return from([context, retry, http]);
  }

  processContextStep(options) {
    return async (operation, forward) => {
      const context = operation.getContext();
      if (typeof context.headers !== "object") {
        context.headers = {};
      }
      // Attach a request ID to each request as a header value so that we can
      // trace requests with their responses and align these with the back end.
      // This improves general traceability and triaging through the front-end
      // to back-end.
      const requestId = Math.random().toString(36).substr(2);
      context.headers[RequestIdHeader] = context.requestId ?? requestId;
      if (sendSessionID && options?.sessionId) {
        context.headers[SessionIdHeader] = options.sessionId;
      }
      operation.setContext(context);

      //console.log(`[gql] requesting '${operation.operationName}' [${requestId}]`);

     const token = cookies.get("DFS");
     context.headers["authorization"] = token ? `bearer ${token}` : "";

      return forward(operation).map((response) => {
        const headers = operation.getContext().headers;
        const res = response;
        if (headers[RequestIdHeader]) {
          res.requestId = headers[RequestIdHeader];
        }
        if (sendSessionID && headers[SessionIdHeader]) {
          res.sessionId = headers[SessionIdHeader];
        }
        return res;
      });
    };
  }
}

// @TODO Move useApollo composable to composables
export function useApollo() {
  return inject(ApolloClientKey);
}

export const ApolloPlugin = {
  install: (app, options) => {
    // console.log("Install apollo plugin", options);
    const config = new ApolloConfig(options);
    const client = config.client;
    app.config.globalProperties.$apollo = client;
    app.provide(ApolloClientKey, client);
  },
};
