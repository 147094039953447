export const CHANGE_EVENTS = {
  INITIAL_SESSION: "INITIAL_SESSION",
  SIGNED_IN: "SIGNED_IN",
  SIGNED_OUT: "SIGNED_OUT",
  SESSION_REFRESHED: "SESSION_REFRESHED"
}

export const SESSION_EVENTS = {
  SESSION_EXPIRED: "SESSION_EXPIRED",
  SESSION_CURRENT: "SESSION_CURRENT",
  SESSION_INVALID: "SESSION_INVALID"
}

export const REFRESH_EVENTS = {
  REFRESH_EXPIRED: "REFRESH_EXPIRED",
}