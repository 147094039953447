import { inject } from 'vue';
// import { cookies } from '@dayforward/core/utils';

export function useDescope() {
  const descope = inject('$descope');

  if (!descope) throw new Error('descope not available.');

  // const token = computed(() => cookies.get("DFS"));

  return { descope };
}