import { computed, ref, readonly } from "vue";
// import { cookies } from "@dayforward/core/utils";
import DescopeClient from "@dayforward/core/services/DescopeClient";

export default {
  install: (app, options) => {
    // process.env.VUE_APP_RELEASE_STAGE
    // console.log("Install descope plugin", options);
    const client = new DescopeClient(process.env.VUE_APP_DESCOPE_API, process.env.VUE_APP_DESCOPE_PROJECT_ID, {
      cookieSession: options?.cookieSession || "DFS",
      cookieRefresh: options?.cookieRefresh || "DFR",
      cookieExpires: options?.cookieExpires || "DFX", 
    });
  
    const currentSession = ref(null);
    client.onSessionChange((_, session) => {
      currentSession.value = session;
    });

    const loadingSession = ref(null);
    const sessionNeverCalled = computed(() => loadingSession.value === null);
    const fetchSession = async () => {
      if (!client) return;
      loadingSession.value = true;
      await client.getSession();
      loadingSession.value = false;
    };
    fetchSession();

    const token = ref(null);
    client.onSessionChange(() => {
      token.value = client.token;
    });

    const descope = {
      client,
      session: readonly(currentSession),
      token: readonly(token),
      loading: readonly(loadingSession),
      sessionNeverCalled
    }

    app.config.globalProperties.$descope = descope;
    app.provide('$descope', descope);
  }
};