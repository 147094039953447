import { isNull, isUndefined } from "@common";
import { useReporter } from "@common/composables";
import { useInterview } from "@composables";
import { toRaw } from "vue";
import { useRouter } from "vue-router";

import { routeNames, STATUS } from "../constants";

// @TODO Move these redirect methods to the useRedirect composable and consolidate redirects
export function useAccountRedirects() {
  const reporter = useReporter();
  const router = useRouter();
  const { invalidPaymentStatus } = useInterview();

  function handleInProgressInterview({ interview, marker }, funnel) {
    const { status } = interview; 
    // Ensure there is a marker value;
    if (isNull(marker)) marker = "initial-step";
      // @PATCH Ensure that interviews that have an invalid quote marker get reset
    if (marker === "quote" && status === STATUS.INCOMPLETE) marker = "initial-step";

    // Check if payment status is valid
    const paymentStatusInvalid = invalidPaymentStatus({ interview, marker }); // Returns boolean and sets marker to quote;
    if (paymentStatusInvalid) reporter.log("Payment status was invalid.");
    if (paymentStatusInvalid) marker = "quote";
  

    const steps = funnel.steps;
    const currentStep = steps.find((step) => step.name === marker);
    const routeParams = currentStep?.routeParams; // Don't believe this is needed anymore
    const name = isUndefined(currentStep) ? routeNames.INTERVIEW_INITIAL_STEP : marker;

    return { route: { name, ...(routeParams && { params: toRaw(routeParams) }) }, marker }
  }

  async function handleTerminalInterview(apollo, { user }) {  
    if (!user) {
      reporter.error("user param missing or is null.");
      return;
    }

    const { id: userId, address } = user
    if (!userId) {
      reporter.error("id is missing user.");
      return;
    }

    const { state } = address;
    const { createInterview } = useInterview(apollo);
    const { payload, success, message } = await createInterview(state, true, userId);
    if (!success) {
      reporter.error("Start over failed:", message);
      return { success };
    }

    const { interviewQuestions } = useInterviewData();
    interviewQuestions.value = payload.questions;

    return { success };
  }

  function handlePendingInterview(flags) {
    if (flags.includes("PAYMENT_FAILED")) return { route: { name: "payment-dashboard" } }; // router.replace("/payment");
    return { route: null }
  }

  function handleOffRamp() {
    router.replace("/or/sorry");
  }

  return {
    handleInProgressInterview,
    handleTerminalInterview,
    handleOffRamp,
    handlePendingInterview,
  };
}
