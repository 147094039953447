import { computed, readonly, ref } from "vue";

const user = ref(null);
const marker = ref(null);

export function useUser() {
  /**
   * Transforms the provided data by merging the account details 
   * with the applicant data from the first interview, if available.
   *
   * @param {Object} data - The user data object.
   * @param {Object} data.account - The account object.
   * @param {Object[]} data.account.interviews - An array of interview objects.
   * @param {Object} [data.account.interviews[].applicant] - The applicant object within an interview.
   * @returns {Object} The transformed account object with merged applicant data, if applicable.
   */
  const transform = (data) => ({ ...data.account, ...(data.account.interviews.length && data.account.interviews[0].applicant)});
  
  /**
   * Update user value with new user data
   * @param {object} data - The new value to merge into the previous user data.
   */
  const update = (data) => ({ ...user.value, ...data });

  // Tier 1 user data properties
  const interview = computed(() => user.value?.interviews?.length ?  user.value.interviews[0] : user.value?.interviewSummaries?.length ? user.value.interviewSummaries[0] : null);
  const quote = computed(() => user.value?.quote ? user.value.quote : null);
  const policy = computed(() => user.value?.policy ? user.value.policy : null);

  // Tier 2 user data properties
  const variant = computed(() => interview.value ? interview.value.product.variant : null);
  const vendor = computed(() => interview.value ? interview.value.product.vendor : null);
  const status = computed(() => interview.value ? interview.value.status : null);
  const flags = computed(() => interview.value.flags);
  const state = computed(() => user.value?.address ? user.value?.address?.state : null)
  
  const isCtl = computed(() => interview.value?.product?.vendor === "CTL")
  return {
    transform,
    update,
    user,
    marker,
    interview, //: readonly(interview),
    quote, //: readonly(interview),
    policy, //: readonly(quote),
    status,
    flags, //: readonly(flags),
    state: readonly(state),
    variant: readonly(variant),
    vendor: readonly(vendor),

    isCtl: readonly(isCtl)
  };
}
