import FUNNEL from "@common/data/funnel.json"; // Global funnel

import Funnel from "../data/funnel.json"; // Local funnel

const baseNames = FUNNEL.at(-1); // Base interview route names

// Prevent ability to modify paths
export const PATHS = Object.freeze({
  ACCOUNT: "/account",
  DEFAULT: "",
  DEMO_APPLICANT_CHECKOUT: "checkout",
  DEMO_APPLICANT_CONSENT: "consent",
  DEMO_APPLICANT_QUOTE: "quote",
  DEMO_APPLICANT_REVIEW: "review",
  DEMO_APPLICANT_SIGN: "sign",
  DEMO_APPLICANT_SIGN_IN: "signin",
  DEMO_APPLICANT_THANKS: "thanks",
  DEMO_APPLICANT_WAITING: "waiting",
  PARAM_ID: ":id",
  OVERVIEW: "overview",
  QUOTE: "/q",
});

export const routeNames = Object.freeze({
  AUTH_SIGN_IN: "signin",
  AUTH_LOG_OUT: "logout",
  HOME: "home",
  ABOUT: "about",
  WHY_DAYFORWARD: "why-dayforward",
  HELP: "help",
  CAREERS: "careers",
  TERMS: "terms-of-use",
  PRIVACY: "privacy-policy",
  FAQ: "faq",
  FAQ_SLUG: "faq-slug",
  DEMO_APPLICANT_CHECKOUT: "demo-applicant-checkout",
  DEMO_APPLICANT_CONSENT: "demo-applicant-consent",
  DEMO_APPLICANT_QUOTE: "demo-applicant-quote",
  DEMO_APPLICANT_REVIEW: "demo-applicant-review",
  DEMO_APPLICANT_SIGN: "demo-applicant-sign",
  DEMO_APPLICANT_SIGN_IN: "demo-applicant-signin",
  DEMO_APPLICANT_SIGNIN_VERIFY: "demo-applicant-signin-verfiy",
  DEMO_APPLICANT_THANKS: "demo-applicant-thanks",
  DEMO_APPLICANT_WAITING: "demo-applicant-waiting",
  IMO: "imo",
  IMO_APPLICANT_CREATE: "imo-create",
  IMO_APPLICANT_DETAIL: "imo-applicant",
  IMO_APPLICANT_ESTIMATE: "imo-estimate",
  IMO_APPLICANT_INDEX: "imo-applicant-index",
  IMO_APPLICANT_QUOTE: "imo-applicant-quote",
  IMO_APPLICANT_APPLICATION: "imo-applicant-application",
  IMO_CONSENT: "imo-consent",
  IMO_CONSENT_CONFIRMED: "consent-confirmed",
  IMO_DASHBOARD: "imo-dashboard",
  IMO_DASHBOARD_APPLICATIONS: "imo-dashboard-applications",
  IMO_DASHBOARD_APPLICATIONS_APPLICANT: "imo-dashboard-applications-applicant",
  IMO_DASHBOARD_POLICIES: "imo-dashboard-policies",
  IMO_DASHBOARD_POLICIES_APPLICANT: "imo-dashboard-policies-applicant",
  IMO_SIGNIN: "imo-signin",
  INTERVIEW: "interview",
  INTERVIEW_BASIC_INFO: baseNames.at(1), // basic-info
  INTERVIEW_INTRO: "intro",
  INTERVIEW_BENEFICIARIES: baseNames.at(9), // beneficiaries
  INTERVIEW_WELCOME_BACK: "welcome-back",
  INTERVIEW_CUSTOMIZE_POLICY: "customize-policy",
  INTERVIEW_DRIVING: baseNames.at(5), // driving
  INTERVIEW_E_SIGN: baseNames.at(13), // esign
  INTERVIEW_HABITS: baseNames.at(6), // habits
  INTERVIEW_HEALTH: baseNames.at(7), // health
  INTERVIEW_HEALTH_CONDITIONS: baseNames.at(8), //health-conditions
  INTERVIEW_HEALTH_CONDITIONS_DIAGNOSIS: "health-conditions-diagnosis",
  INTERVIEW_HEALTH_CONDITIONS_DETAILS: "health-conditions-details",
  INTERVIEW_INCOME: baseNames.at(3), // income
  INTERVIEW_INITIAL_STEP: baseNames.at(0), // initial-step
  INTERVIEW_INSURANCE_HISTORY: baseNames.at(10), // insurance-history
  INTERVIEW_LOCATION: baseNames.at(2), // location-info
  INTERVIEW_PAYMENT: baseNames.at(12), // payment
  INTERVIEW_QUOTE: baseNames.at(11), // quote
  INTERVIEW_THANKS: baseNames.at(14), // thanks
  INTERVIEW_REVIEW: "i-review",
  LICENSES: "licenses",
  MANUAL_UNDERWRITING: "manual-underwriting",
  OFF_RAMP_WAITLIST: "off-ramp-waitlist",
  OFF_RAMP_INELIGIBLE: "off-ramp-ineligible",
  PROFILE: "account",
  QUOTE: "q",
  Q_SLUG: "q-slug",
  Q_ESTIMATE: "q-estimate",
  QUICK_PRICE: "quick-price",
  REVIEWS: "reviews",
  CONTACT: "contact",
  ERROR_PAGE: "error-page",
  NOT_FOUND: "404",
  ACCOUNT: "account",
  OVERVIEW: "overview",
});

// Current Funnel slugs
const [slugs] = Funnel;
export const SLUGS = Object.freeze(slugs.split(" ").reduce((a, i) => ({ ...a, [i]: i }), {}));
//
