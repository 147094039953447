import FUNNEL from "../data/funnel.json";

/**
 * Get markers from the last element of the FUNNEL array.
 * Filters out values containing "##" and transforms them into an object
 * with keys derived from the values (converted to uppercase and with hyphens replaced by underscores).
 *
 * @returns {Object<string, string>} An object where the keys are the transformed values, and the values are the original strings.
 */
export function getMarkers() {
  return FUNNEL[FUNNEL.length - 1].filter((value) => !value.includes("##")).reduce((a, i) => ({ ...a, [i.toUpperCase().replace("-", "_")]: i}), {});
}
