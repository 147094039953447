// Needs refactor to consolidate repeat code.
export function useLocalStorage() {
  function set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  function remove(key) {
    localStorage.removeItem(key);
  }

  function get(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  return {
    set,
    remove,
    get,
  };
}

export function useSessionStorage() {
  function set(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  function remove(key) {
    sessionStorage.removeItem(key);
  }

  function get(key) {
    return JSON.parse(sessionStorage.getItem(key));
  }

  return {
    set,
    remove,
    get,
  };
}
