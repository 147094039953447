//import { useMutaion } from "./mutation";
import VERIFY_ACCOUNT from "@api/graphql/getAccountVerification.query.gql";
import ADD_TO_WAITINGLIST from "@api/graphql/addToWaitlist.mutation.gql";
import { interview as GET_INTERVIEW } from "@api/graphql/v2/interview.queries.gql";
import { useReporter } from "@common/composables";
import { cookies } from "@dayforward/core/utils";
import { readonly, ref } from "vue";

// import { ROLES, STATUS } from "../constants";
import { interviewStatus as STATUS } from "../constants";
import QUESTIONS from "../data/questions.json";
import ADD_CREDIT_CARD from "../graphql/addCreditCard.mutation.gql";
import ADD_INTERACTION from "../graphql/addInteraction.mutation.gql";
import ADD_INTERVIEW_META from "../graphql/addInterviewMeta.mutation.gql";
import ADD_MARKER from "../graphql/addMarker.mutation.gql";
import CREATE_ACCOUNT from "../graphql/createAccount.mutation.gql";
import CREATE_APPLICANT from "../graphql/createApplicant.mutation.gql";
import CREATE_BILLING_ACCOUNT from "../graphql/createBillingAccount.mutaion.gql";
import CREATE_INTERVIEW from "../graphql/createInterview.mutation.gql";
import GET_ACCOUNT from "../graphql/getAccount.query.gql";
import ACCOUNT_VERIFICATION from "../graphql/getAccountVerification.query.gql";
import GET_ADDRESS from "../graphql/getAddress.query.gql";
import GET_ANSWERS from "../graphql/getAnswers.query.gql";
import GET_CREDIT_CARDS from "../graphql/getCreditCards.query.gql";
import GET_DIAGNOSES from "../graphql/getDiagnoses.query.gql";
import GET_DIAGNOSIS from "../graphql/getDiagnosis.query.gql";
import GET_EMAIL_REDIRECT from "../graphql/getEmailRedirect.query.gql";
import GET_INTERVIEW_QUESTIONS from "../graphql/getInterviewQuestions.query.gql";
import GET_INTERVIEW_STATUS from "../graphql/getInterviewStatus.query.gql";
import GET_INTERVIEW_MARKER from "../graphql/getInteviewMarker.query.gql";
import GET_MEDICATION from "../graphql/getMedication.query.gql";
import GET_MEDICATIONS from "../graphql/getMedications.query.gql";
import GET_MILITARY_DISCLOSURE from "../graphql/getMilitaryDisclosure.query.gql";
import GET_OCCUPATION from "../graphql/getOccupation.query.gql";
import GET_OCCUPATIONS from "../graphql/getOccupations.query.gql";
import GET_POLICY_DOWNLOAD from "../graphql/getPolicyDownload.query.gql";
import GET_PRODUCT_QUESTIONS from "../graphql/getProductQuestions.query.gql";
import GET_QUESTIONS from "../graphql/getQuestions.query.gql";
import GET_QUOTE from "../graphql/getQuote.query.gql";
import GET_QUOTE_ESTIMATE_OVERRIDE from "../graphql/getQuoteEstimateOverride.query.gql";
import REMOVE_CREDIT_CARD from "../graphql/removeCreditCard.mutation.gql";
import UPDATE_ACCOUNT from "../graphql/updateAccount.mutation.gql";
import UPDATE_INTERVIEW from "../graphql/updateInterview.mutation.gql";
import UPDATE_QUOTE from "../graphql/updateQuote.mutation.gql";
import { useDecision } from "./desision";
import { useMutation } from "./mutation";
import { useQuery } from "./query";
import { useTraversables } from "./traversables";
// Method naming for queries should start with query* and mutations should start with mutate*

/**
 * Generic resource query method to simplify api calls
 * Returns an object with a payload, success status and message.
 * @param {object} apollo - The apollo object
 * @param {string} prop - The prop from the response data object
 * @param {object} resource - The apollo query
 * @param {object} variables - variables for the query
 * @returns {object}
 */
async function useResource(apollo, prop, resource, variables) {
  const reporter = useReporter();
  const payload = ref(null);
  const success = ref(true);
  const message = ref("");

  const { query } = useQuery(apollo);
  try {
    const response = await query(resource, variables);
    const { data } = response;
    payload.value = data;
  } catch (error) {
    reporter.error("Error", error);
    success.value = false;
    message.value = `Unable to query ${prop}`;
  }

  return {
    payload,
    success: readonly(success),
    message: readonly(message),
  };
}

/**
 * Parent method that returns address query and mutation methods
 * @param {object} apollo
 * @returns {object} address
 */
export function useAddress(apollo) {
  /**
   * Returns an object containing the payload, success, and message properties for getAddress query.
   * @param {string} query
   * * @param {string} preferState
   * @returns {object}
   */
  async function queryAddress(query, preferStates, preferState) {
    const variables = { query, preferStates, preferState };
    return await useResource(apollo, "address", GET_ADDRESS, variables);
  }

  return {
    queryAddress,
  };
}

/**
 * Parent method that returns agent query and mutation methods
 * @param {object} apollo
 * @returns {object} applicant
 */
export function useApplicant(apollo) {
  const { mutation } = useMutation(apollo);
  /**
   * Returns an object containing the payload, success, and message properties for getAgent query.
   * @param {string} email - applicant's email
   * @param {string} state - applicant's state
   * @param {string} params - query params use in email sigin url
   * @returns {object}
   */
  async function mutateApplicant(email, state, id, params = "source=web&imo=true") {
    const variables = { email, state, queryParams: params };
    const { response } = await mutation(CREATE_APPLICANT, variables);
    const { data } = response;
    if (!data.createAccountWithBoundInterview.success)
      return {
        payload: null,
        success: data.createAccountWithBoundInterview.success,
        message: data.createAccountWithBoundInterview.message,
      };
    return {
      payload: {
        account: data.createAccountWithBoundInterview.account,
        interview: data.createAccountWithBoundInterview.interview,
      },
      success: data.createAccountWithBoundInterview.success,
      message: data.createAccountWithBoundInterview.message,
    };
  }

  return {
    mutateApplicant,
  };
}

const accountLoaded = ref(false)
export function useAccount(apollo) {
  const { mutation } = useMutation(apollo);

  async function queryEmailRedirect(email) {
    const variables = { email };
    return await useResource(apollo, "getEmailRedirect", GET_EMAIL_REDIRECT, variables);
  }

  async function queryAccountVerification(email) {
    const variables = { email };
    return await useResource(apollo, "accountInfo", ACCOUNT_VERIFICATION, variables);
  }

  async function queryAccount() {
    const token = cookies.get("DFS");
    const variables = { token };
    const response =  await useResource(apollo, "account", GET_ACCOUNT, variables);
    accountLoaded.value = true;
    return response
  }

  async function mutateAccount(variables) {
    // const variables = { id, firstName, lastName, email, phoneNumber };
    const { response } = await mutation(UPDATE_ACCOUNT, variables);
    const { data, success, message } = response;

    if (!data) {
      return {
        payload: null,
        success,
        message,
      };
    }

    return {
      payload: data.updateAccount,
      success: true,
      message: null,
    };
  }
  async function createAccount(accountInput) {
    const variables = { accountInput }; // variables { accountInput: accountInput }
    const { response } = await mutation(CREATE_ACCOUNT, variables);
    const { data, success, message } = response;
    if (!data) {
      return {
        payload: null,
        success,
        message,
      };
    }

    return {
      payload: data.createAccount,
      success: true,
      message: null,
    };
  }

  async function createBillingAccount() {
    const { response } = await mutation(CREATE_BILLING_ACCOUNT);
    return response;
  }

  async function queryPolicyDownload(id) {
    const variables = { id };
    return await useResource(apollo, "policyDownloadURL", GET_POLICY_DOWNLOAD, variables);
  }

  async function verifyAccount (value, mode = "email") {
    const variables = { ...(mode === "email" ? { email: value } : { phone: value }) }
    return await useResource(apollo, "accountVerification", VERIFY_ACCOUNT, variables);
  }

  return {
    accountLoaded: readonly(accountLoaded),
    createAccount,
    queryAccount,
    mutateAccount,
    queryAccountVerification,
    createBillingAccount,
    queryEmailRedirect,
    queryPolicyDownload,
    verifyAccount,
  };
}

export function useCreditCards(apollo) {
  const { mutation } = useMutation(apollo);

  async function queryCreditCards() {
    return await useResource(apollo, "creditCards", GET_CREDIT_CARDS);
  }

  async function addCreditCard(token, setDefault) {
    const variables = { token, setDefault };
    const { response } = await mutation(ADD_CREDIT_CARD, variables);
    const { data, success, message } = response;
    if (!data) {
      return {
        payload: null,
        success,
        message,
      };
    }
    return {
      payload: data.attachCreditCard,
      success: true,
      message: "",
    };
  }

  async function deleteCreditCard(id) {
    const variables = { id };
    try {
      const { response } = await mutation(REMOVE_CREDIT_CARD, variables);
      return { success: response.data.detachCreditCard };
    } catch (error) {
      console.log("Error", error);
      return { success: false };
    }
  }

  return {
    addCreditCard,
    deleteCreditCard,
    queryCreditCards,
  };
}

/**
 * Parent method interview query and mutations for agents, agency, ect
 * @param {object} apollo
 * @returns {object}
 */
export function useInterview(apollo) {
  const { mutation } = useMutation(apollo);

  async function addInterviewMeta(id, update) {
    const variables = { id, update };
    const { response } = await mutation(ADD_INTERVIEW_META, variables);
    const { data, success, message } = response;
    if (!data) return { payload: null, success, message };
    return {
      payload: data.addInterviewMarker,
      success: true,
      message: "",
    };
  }

  async function addMarker(id, name, metadata) {
    const variables = { id, name, metadata };
    const { response } = await mutation(ADD_MARKER, variables);
    const { data, success, message } = response;
    if (!data) {
      return {
        payload: null,
        success,
        message,
      };
    }
    return {
      payload: data.addInterviewMarker,
      success: true,
      message: "",
    };
  }

  async function createInterview(state, bind = true, accountID) {
    const variables = { state, bind, accountID };
    const { response } = await mutation(CREATE_INTERVIEW, variables);
    const { data, success, message } = response;
    if (!data) {
      return {
        payload: null,
        success,
        message,
      };
    }

    return {
      payload: data.createInterview,
      success: true,
      message: "",
    };
  }

  async function queryInterview(id, shallow = true) {
    const variables = { id, shallow, deep: !shallow };
    return await useResource(apollo, "interview", GET_INTERVIEW, variables);
  }

  async function queryInterviewMarker(id, name) {
    const variables = { id, name };
    return await useResource(apollo, "getInterviewMarkerByName", GET_INTERVIEW_MARKER, variables);
  }

  async function queryInterviewStatus(id) {
    const variables = { id };
    return await useResource(apollo, "interview", GET_INTERVIEW_STATUS, variables);
  }

  async function mutateInterview(id, answers) {
    const variables = { id, answers };
    const { mutation } = useMutation(apollo);
    const { response } = await mutation(UPDATE_INTERVIEW, variables);
    const { data } = response;
    const errors = data.putInterviewAnswers.filter((answer) => !answer.success);

    if (errors.length) return { payload: [], errors, success: false };

    return { payload: data.putInterviewAnswers, success: true, errors: null };
  }

  async function queryQuestions(id) {
    const variables = { id };
    return await useResource(apollo, "interview", GET_QUESTIONS, variables);
  }

  async function queryMilitaryDisclosure(id) {
    const variables = { id };
    return await useResource(apollo, "interview", GET_MILITARY_DISCLOSURE, variables);
  }

  // Helper methods
  /**
   * Checks if an interview has interactions.
   * @param {Object} interview - The interview object.
   * @param {Array} [interview.interactions] - The list of interactions in the interview.
   * @returns {boolean} True if the interview has interactions, false otherwise.
   */
  function hasInteractions(interview) {
    return "interactions" in interview && interview.interactions.length;
  }


  /**
   * Determines if the interactions include a received finalized quote.
   * @param {Array} interactions - The list of interactions.
   * @returns {boolean} True if a finalized quote was received, false otherwise.
   */
  function receivedFinalQuote(interactions) {
    if (hasInteractions()) return interactions.some(({ type }) => type === interviewInteraction.RECEIVED_FINALIZED_QUOTE);
    return false;
  }
  
  /**
   * Determines if payment information has been entered.
   * @param {Array} interactions - The list of interactions.
   * @returns {boolean} True if payment information was entered, false otherwise.
   */
  function enteredPaymentInfo(interactions) {
    if (hasInteractions()) return interactions.some(({ type }) => type === interviewInteraction.PAYMENT_INFO_ENTERED);
    return false;
  }

  /**
   * Checks if the payment status is invalid based on the given parameters.
   * @param {Object} params - The parameters object.
   * @param {string} params.status - The current status.
   * @param {string} params.marker - The current marker.
   * @param {Object} params.quote - The quote object.
   * @param {boolean} [params.quote.isEstimate] - Indicates if the quote is an estimate.
   * @param {boolean} [params.quote.locked] - Indicates if the quote is locked.
   * @param {Array} params.interactions - The list of interactions.
   * @returns {boolean} True if the payment status is invalid, false otherwise.
   */
  function invalidPaymentStatus({ status, marker, quote, interactions }) {
    const invalidStatus =
      status === STATUS.READY_FOR_CHECKOUT &&
      marker === "payment" &&
      isFalse(quote?.isEstimate) &&
      isFalse(quote?.locked) &&
      isFalse(receivedFinalQuote(interactions)) &&
      isFalse(enteredPaymentInfo(interactions));
    
    // if (invalidStatus) marker.value = "quote"
    return invalidStatus;
  }

  /**
   * Checks if the payment status is valid.
   * @param {Object} params - The parameters object.
   * @param {string} params.status - The current status.
   * @param {string} params.marker - The current marker.
   * @param {Array} params.interactions - The list of interactions.
   * @returns {boolean} True if the payment status is valid, false otherwise.
   */
  function paymentStatus({ status, marker, interactions }) {
    return (
      status === STATUS.READY_FOR_CHECKOUT &&
      marker === "payment" &&
      isTrue(receivedFinalQuote(interactions)) &&
      isFalse(enteredPaymentInfo(interactions))
    );
  }

  /**
   * Checks if the quote status is valid.
   * @param {Object} params - The parameters object.
   * @param {string} params.status - The current status.
   * @param {string} params.marker - The current marker.
   * @param {Object} params.quote - The quote object.
   * @param {boolean} [params.quote.isEstimate] - Indicates if the quote is an estimate.
   * @param {boolean} [params.quote.locked] - Indicates if the quote is locked.
   * @returns {boolean} True if the quote status is valid, false otherwise.
   */
  function quoteStatus({ status, marker, quote }) {
    return (
      status === STATUS.READY_FOR_CHECKOUT &&
      marker === "quote" &&
      isFalse(quote?.isEstimate) &&
      isFalse(quote?.locked)
    );
  }

  return {
    addMarker,
    addInterviewMeta,
    createInterview,
    mutateInterview,
    queryInterview,
    queryInterviewMarker,
    queryInterviewStatus,
    queryMilitaryDisclosure,
    queryQuestions,
    hasInteractions,
    invalidPaymentStatus,
    paymentStatus,
    quoteStatus,
  };
}

export function useOccupation(apollo) {
  async function queryOccupation(keys) {
    const variables = { keys };
    return await useResource(apollo, "occupations", GET_OCCUPATION, variables);
  }

  async function queryOccupations(term) {
    const variables = { term };
    return await useResource(apollo, "occupations", GET_OCCUPATIONS, variables);
  }

  return {
    queryOccupation,
    queryOccupations,
  };
}

export function useQuestions(apollo) {
  async function queryAnswers(id, keys = [], concerns = [], categories = []) {
    const variables = { id, keys, concerns, categories };
    return await useResource(apollo, "questions", GET_ANSWERS, variables);
  }

  async function queryProductQuestions(variant, vendor) {
    if (!variant) variant = "ICC";
    if (!vendor) vendor = "DAYFORWARD";
    const variables = { variant, vendor };
    return await useResource(apollo, "allQuestionsForProductVariant", GET_PRODUCT_QUESTIONS, variables);
  }

  async function queryInterviewQuestions(id, keys = [], concerns = [], categories = []) {
    const variables = { id, keys, concerns, categories };
    return await useResource(apollo, "allQuestionsForProductVariant", GET_INTERVIEW_QUESTIONS, variables);
  }

  async function queryQuestion(slug, formState, direction) {
    const controller = direction === "to";
    const { decideTo, decideFrom } = useDecision();
    const { traverseFrom, traverseTo } = useTraversables();
    const decision = controller ? decideTo(slug, formState) : decideFrom(slug, formState);
    const path = controller ? traverseTo(slug, decision) : traverseFrom(slug, decision);
    const response = QUESTIONS.find((q) => q.slug === path);
    if (!response) return { sucess: !!response, payload: null };
    return { success: !!response, payload: { ...response } };
  }

  return {
    queryAnswers,
    queryQuestion,
    queryProductQuestions,
    queryInterviewQuestions,
  };
}

const quote = ref(null)
export function useQuote(apollo) {
  const { mutation } = useMutation(apollo);

  async function queryQuote(id, override) {
    const variables = { interviewID: id, override };
    return await useResource(apollo, "quote", GET_QUOTE, variables);
  }

  async function mutateQuote(id, override) {
    const variables = { interviewID: id, override };
    const { response } = await mutation(UPDATE_QUOTE, variables);
    const { data, success, message } = response;

    if (!data) return { payload: null, success, message };
    return { payload: data.quoteUpdate, success: true, message: "" };
  }

  return {
    quote,
    queryQuote,
    mutateQuote,
  };
}

export function useQuoteEstimate(apollo) {
  async function queryQuoteEstimateOverride(variables) {
    return await useResource(apollo, "quoteEstimateOverride", GET_QUOTE_ESTIMATE_OVERRIDE, variables);
  }
  return {
    queryQuoteEstimateOverride,
  };
}

export function useDiagnosis(apollo) {
  async function queryDiagnoses(term) {
    const variables = { term };
    return await useResource(apollo, "diagnoses", GET_DIAGNOSES, variables);
  }
  async function queryDiagnosis(codes) {
    const variables = { codes };
    return await useResource(apollo, "diagnoses", GET_DIAGNOSIS, variables);
  }

  return {
    queryDiagnoses,
    queryDiagnosis,
  };
}

export function useMedications(apollo) {
  async function queryMedications(match) {
    const variables = { match };
    return await useResource(apollo, "medications", GET_MEDICATIONS, variables);
  }
  async function queryMedication(names) {
    const variables = { names };
    return await useResource(apollo, "medications", GET_MEDICATION, variables);
  }

  return {
    queryMedication,
    queryMedications,
  };
}

export function useInteractions(apollo) {
  const { mutation } = useMutation(apollo);

  async function setInteraction(id, update) {
    const variables = { id, update };
    const { response } = await mutation(ADD_INTERACTION, variables);

    if (response.data.error) return { success: false, message: response.data.message };
    return { success: true, message: "" };
  }

  return {
    setInteraction,
  };
}

export function useWaitlist(apollo) {
  const { mutation } = useMutation(apollo);

  async function addToWaitlist(entry) {
    const variables = { entry };
    const { response } = await mutation(ADD_TO_WAITINGLIST, variables);
    const { success, message } = response.data.createWaitlistEntry;
    return { success, message };
  }
  return {
    addToWaitlist,
  };
}
