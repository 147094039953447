import { inject } from "vue";

import { FlagsmithFlagger, NoOpFlagger } from "../services/features";

export const FeatureFlagClientKey = "featureFlagClientKey";
export const FeatureFlagProviderNameKey = "featureFlagProviderNameKey";

export function useFeatureFlags() {
  return inject(FeatureFlagClientKey);
}

export default {
  install: async (app, options) => {
    // console.log("Install feature flags plugin", options);
    const { provider, settings } = options;
    const client = provider === "flagsmith" && settings ? new FlagsmithFlagger(options) : new NoOpFlagger();
    if (client) client.init();
    if (!client) throw new Error("Feature flagging was not configured correctly");

    app.provide(FeatureFlagClientKey, client);
    app.provide(FeatureFlagProviderNameKey, provider);
    app.config.globalProperties.$features = client;
  },
};
