<template>
  <div :class="$style.user">
    <div :class="$style.account">
      <BaseAvatar :user="user" />
    </div>
  </div>
</template>

<script setup>
import BaseAvatar from "@dayforward/common/src/components/BaseAvatar.vue";

import { useUser } from "@composables";

const { user } = useUser();
</script>

<style lang="scss" module>
.account,
.user {
  display: flex;
}

.account {
  margin-right: var(--space-xs);
}
.user {
  align-items: center;
}

.name,
.agency {
  line-height: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.name {
  display: block;
  font-weight: 600;
}

.agency {
  display: block;
  font-size: var(--base-text-sm);
}

.meta {
  max-width: 200px;
  margin-left: var(--space-xs);
  overflow: hidden;
}

.logout {
  margin-right: -12px;
}
</style>
