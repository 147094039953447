import { ref } from "vue";

const cookies = ref(null);

export function useCookies() {
  const get = (name) => {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return match ? match[2] : null;
  }

  const set = (name, value) => {
    const days = 7;  // Expiry in days
    // Set expiration date
    const expires = new Date();
    expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000)); // Convert days to milliseconds
    const expiresStr = `expires=${expires.toUTCString()}`;

    // Set the cookie
    document.cookie = `${name}=${value}; ${expiresStr}; path=/; secure; samesite=Lax`;
  }

  const find = (name) => {
    let cookie = cookies.value[name]
    if (!cookie) cookie = get(name)
    return cookie;
  }

  const store = () => {
    const cookieArray = document.cookie.split(';');
    cookieArray.forEach(cookie => {
      const [name, value] = cookie.trim().split('=');
      cookies.value[name] = value;
    });
  }

  onMounted(() => {
    store();
  });

  return {
    cookies,
    get,
    set,
    find,
  };
}
