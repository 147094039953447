import { routeNames } from "../constants";

export const authRoutes = [
  {
    path: "/signin",
    name: routeNames.AUTH_SIGN_IN,
    component: () => import("../pages/Signin.vue"),
  },
  {
    path: "/signin/verify",
    name: "signin-verify",
    component: () => import("../pages/v3/AuthSigninVerify.vue"),
  }
];
