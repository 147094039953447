export const cookies = {
  set: (name, value, expiresInDays = 7) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + (expiresInDays * 24 * 60 * 60 * 1000));
    const expiresStr = `expires=${expires.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expiresStr}; path=/; secure; samesite=Lax;`;
  },
  get: (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
  },
  delete: (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
}
