export async function _fetch(url, { method = 'POST', body = null, headers = {} }) {
  const config = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    body: body ? JSON.stringify(body) : null
  };

  const response = await fetch(url, config);
  const data = await response.json();
  return {
    data,
    success: response.ok,
    error: response.ok ? '' : data.errorDescription || 'An error occurred'
  };
}

// Private helper function for error handling
export function _handleError(error) {
  return { payload: null, success: false, error: error.message || 'An unexpected error occurred' };
}
