import { inject } from "vue";

import { NoOpAnalyticsReporter, SegmentReporter } from "../services/analytics";

export const AnalyticsReporterKey = "analyticsReporter";
export const AnalyticsProviderKey = "analyticsProvider";

export function useAnalytics() {
  return inject(AnalyticsReporterKey);
}

export const AnalyticsPlugin = {
  install: (app, options) => {
    // console.log("Install analytics plugin", options);
    
    const { provider, settings } = options;
    const reporter = provider === "segment" && settings ? new SegmentReporter(options).init() :  new NoOpAnalyticsReporter();
    
    if (!reporter) throw new Error("Analytics was not configured correctly");

    app.provide(AnalyticsReporterKey, reporter);
    app.provide(AnalyticsProviderKey, provider);
    app.config.globalProperties.$analytics = reporter;
  },
};
