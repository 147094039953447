import { useReporter } from "@common/composables";
import { defineStore } from "pinia";

import { accountQuery, interviewStatusQuery, quoteQuery } from "./modules/queries";

const ICC = "ICC";

export const useInterviewStore = defineStore("interview", {
  state: () => ({
    $marker: null,
    $interview: null, // Depreciated move to getters @TODO refactor to user.store
    $quote: null, // Use
    currentStepIndex: undefined, // Not used except here
    productVariant: undefined, // Depreciated moved to varient @TODO reactor to varient
    accountId: undefined,
    familyMembers: [], // Health && FamilyHealthModal
    userRole: "", // Depreciated @TODO refactor to user.store
    metadata: [],
    account: null, // Depreciated @TODO refactor to user.store
  }),
  getters: {
    id: (state) => {
      return state.interview ? state.interview.id : null;
    },
    variant: (state) => {
      return state.interview ? state.interview.product.variant : null;
    },
    vendor: (state) => {
      return state.interview ? state.interview.product.vendor : null;
    },
    icc: (state) => {
      return state.variant === ICC;
    },
    marker: (state) => {
      return state.$marker;
    },
    status: (state) => {
      return state.interview ? state.interview.status : null;
    },
    flags: (state) => {
      return state.interview && state.interview.policy ? state.interview.policy.flags : null;
    },
    interview: (state) => {
      return state.$interview;
    },
    quote: (state) => {
      return state.$quote ? state.$quote : null;
    },
    estimate: (state) => {
      return state.quote ? state.quote.isEstimate : false;
    },
  },
  actions: {
    setState(prop, data) {
      this[prop] = data;
    },
    setAccountId(id) {
      this.accountId = id;
    },
    setPosition(position) {
      this.position = position;
    },
    setInterviewIdAndAccountId({ accountId }) {
      this.setAccountId(accountId);
    },
    setMetadata(metadata) {
      this.metadata.push(metadata);
    },
    setInterviewDetails(interview) {
      this.$interview = interview;
    },
    setAccountDetails(account) {
      this.account = account;
      this.setAccountId(account?.id);
    },
    async updateInterviewStatus(apollo) {
      const reporter = useReporter();
      try {
        let res = await apollo.query({
          query: interviewStatusQuery,
          variables: {
            id: this.id,
          },
        });
        const { status } = res.data.interview;
        this.$interview = { ...this.$interview, status };
      } catch (e) {
        reporter.error(e);
        reporter.error(`Failed to get interview status for id ${this.id}`);
      }
    },
    async getQuote(apollo) {
      const reporter = useReporter();
      try {
        let res = await apollo.query({
          query: quoteQuery,
          variables: {
            interviewID: this.id,
          },
        });
        this.setState("$quote", res.data.quote);
      } catch (e) {
        reporter.error(e);
      }
    },
  },
});
