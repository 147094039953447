import { datadogRum } from "@datadog/browser-rum";
// import { inject } from "vue";
// DD_RUM.getInternalContext()

export function useReporter() {
  // const client = inject("ReporterKey");
  
  // https://docs.datadoghq.com/real_user_monitoring/guide/send-rum-custom-actions/?tab=npm
  function action(action, options = {}) {
    // console.log(action, options);
    // if (datadogRum) datadogRum.addAction(action, options);
  }

  function log(...args) { console.log("🔍", ...args) }

  // https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#collect-errors-manually
  function error(...args) { console.error("‼️", ...args) }

  function warn(...args) { console.warn("⚠️", ...args) }

  function info(...args) { console.info("💡", ...args) }
  // https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#identify-user-session
  function identify(options) {
    // console.log("identified:", { ...options });
    // if (datadogRum) datadogRum.setUser(options);
  }
  
  return {
    // client,
    action,
    error,
    identify,
    info,
    log,
    warn,
  }
}