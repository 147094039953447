/**
 * Auth store should handle signin, logout, auth sessions, ect.
 */
import { defineStore } from "pinia";

import { STORAGE_KEYS, useSessionStorage } from "../composables/storage";

const { get: getSession } = useSessionStorage();
const sessionId = getSession(STORAGE_KEYS.DF_SESSION_ID);

export const useAuthStore = defineStore("auth", {
  state: () => {
    return {
      $id: sessionId ? sessionId.id : Math.random().toString(36).substring(2),
      $authenticated: false,
      $session: null,
    };
  },
  getters: {
    id: (state) => {
      return state.$id;
    },
    authenticated: (state) => {
      return state.$authenticated;
    },
    session: (state) => {
      return state.$session;
    },
  },
  actions: {
    setState(prop, data) {
      this[prop] = data;
    },
  },
});
