import { cookies, _fetch, _handleError } from "../utils";
import { CHANGE_EVENTS, REFRESH_EVENTS, SESSION_EVENTS } from "../lib/constants";
// import { AuthSignError } from "./errors";

/**
 * @class DescopeClient
 * Handles authentication and session management for Descope.
 */
export default class DescopeClient {
  // #token = null;
  constructor(url, projectId, options = {}) {
    // Required values
    this.url = url;
    this.projectId = projectId;
    // Default values
    this.authUrl = options.authUrl || `${url}/v1/auth`;
    this.cookieSessionName = options.cookieSession || 'WBS';
    this.cookieRefreshName = options.cookieRefresh || 'WBR';
    this.cookieExpiresName = options.cookieExpires || 'WBX';
    // Session data
    this.currentSession = null;
    this.currentUser = null;

    this.listeners = [];
  }

  /**
   * Registers a callback for session changes.
   * @param {Function} callback - Function to execute on session change.
   * @returns {Function} Unsubscribe function to remove the listener.
   */
  onSessionChange(callback) {
    this.listeners.push(callback);
    callback("INITIAL_SESSION", this.currentSession);
    return () => {
      this.listeners = this.listeners.filter(listener => listener !== callback);
    };
  }

  /**
   * Triggers session change events and notifies all listeners.
   * @param {string} event - The event type.
   * @param {object|null} session - The session data.
   */
  triggerSessionChange(event, session) {
    this.currentSession = session?.user ? { user: session.user } : session;
    this.listeners.forEach((callback) => callback(event, this.currentSession));
  }

  /**
   * Sign up or sign in using email and a specified type.
   * @param {string} email - User email.
   * @param {object} options - Options containing the type.
   * @returns {Promise<object>} The response data.
   */
  async signin(email, options = { type: "email" }) {
    const { type } = options;
    if (!email || !type ) throw createError({ statusCode: 400, statusMessage: "Email or options.type is missing on request." });
    try {
      const headers = this._createAuthorizationHeader();
      const response = await _fetch(`${this.authUrl}/otp/signin/${type}`, {
        headers,
        body: { loginId: email }
      })

      if (!response.success) return response;
      return { payload: { ...response.data }, success: true };
    } catch (error) { 
      // Handle sign in errors
      return _handleError(error);
    }
  }

  /**
   * Verifies an OTP code and saves session cookies if successful.
   * @param {object} params - Verification parameters which require email, code and types.
   * @param {string} params.email - Verification parameters which require email, code and types.
   * @param {string} params.code - Verification parameters which require email, code and types.
   * @param {string} params.type - Verification parameters which require email, code and types.
   * @returns {Promise<object>} The response data.
   */
  async verify({ email, code, type }) {
    if (!email || !code || !type) throw createError({ statusCode: 400, statusMessage: "Email, code and/or type is missing on request." });
    try {
      const headers = this._createAuthorizationHeader();
      const response = await _fetch(`${this.authUrl}/otp/verify/${type}`, {
        headers,
        body: { loginId: email, code}
      });

      if (!response.success) return response;

      this._saveSessionCookies(response.data);
      this.triggerSessionChange(CHANGE_EVENTS['SIGNED_IN'], response.data);

      return { payload: { ...response.data }, success: true, error: null };
    } catch (error) {
      // Handle sign in errors
      return _handleError(error);
    }
  }

  /**
 * Verifies a magic link token.
 * @param {string} token - The parameters for verifying the token.
 * @return {Promise<Object>} A promise that resolves to an object containing the verification result.
 * @property {Object} payload - The response data from the verification process.
 * @property {boolean} success - Indicates if the verification was successful.
 * @property {Object|null} error - Contains error details if the verification failed, or null if successful.
 */
  async verifyToken(token) {
    try {
      const headers = this._createAuthorizationHeader();
      const response = await _fetch(`${this.authUrl}/magiclink/verify`, {
        headers,
        body: { token }
      });

      this._saveSessionCookies(response.data);
      this.triggerSessionChange(CHANGE_EVENTS['SIGNED_IN'], response.data);

      return { payload: { ...response.data }, success: true, error: null };
    } catch (error) {
      return _handleError(error);
    }
  }
  /**
   * Logs out the user and removes session cookies.
   * @returns {Promise<object>} The response data.
   */
  async logout() {
    const headers = this._createAuthorizationHeader(true);
    try {
      const response = await _fetch(`${this.authUrl}/logout`, { headers });
      this._removeSessionCookies();
      this.currentUser = null;
      this.triggerSessionChange(CHANGE_EVENTS['SIGNED_OUT'], null);
      return response;
    } catch (error) {
      // Handle sign in errors
      return _handleError(error);
    }
  }
  
  /**
   * Refreshes the session using a refresh token.
   * @returns {Promise<object>} The response data.
   */
  async refresh() {
    try {
      const headers = this._createAuthorizationHeader(true);
      const response = await _fetch(`${this.authUrl}/refresh`, { headers });
      this.triggerSessionChange(CHANGE_EVENTS['SESSION_REFRESHED'], response.data);
      return { data: { session: { user: { ...response.data } }}, error: null };
    } catch (error) {
      // Handle sign in errors
      return _handleError(error);
    }
  }

  /**
   * Returns the current session.
   * @returns {object|null} The session data.
   */
  get session() {
    return this._useSession().session;
  }

  /**
   * Returns if the session is expired.
   * @returns {boolean} true if the session is expired.
   */
  sessionExpired() {
    const sessionState = this._validateSessionState();
    if (!sessionState || sessionState === "EVENT_INVALID") return true;
    if (sessionState === (SESSION_EVENTS['SESSION_EXPIRED'] || REFRESH_EVENTS['REFRESH_EXPIRED'])) return true;
    return false;
  }

  /**
   * Returns the current session.
   * @returns {object|null} The session data.
   */
  async getSession() {
    return await this._loadSession();
  };

  /**
   * This validates the session state base on cookie expires value and will either logout expired refresh tokens, refresh session tokens
   * or check get user from current session. The cookies could be insecure so check the descope server for a valid user.
   *
   * @return {Promise<Object>} A promise that resolves to an object with session data, success status, and potential errors.
   */
  async _loadSession() {
    // Check if there is a valid session state
    const sessionState = this._validateSessionState()

    if (!sessionState) return { data: { session: null }, success: true };

    if (sessionState === REFRESH_EVENTS['REFRESH_EXPIRED']) {
      this.logout();
      return { data: { session: null }, success: true,  error: null };
    }
  
    if (sessionState === SESSION_EVENTS['SESSION_EXPIRED']) {
      const { refreshToken, user } = this._useSession();
      const { data } = await this._callSessionToken(refreshToken);;
      if (!user) await this._sessionUser();
      this.triggerSessionChange(CHANGE_EVENTS['SESSION_REFRESHED'], data);
      return { data: { session: { user: this.currentUser } }, success: true, error: null }
    }

    if (sessionState === SESSION_EVENTS['SESSION_CURRENT']) {
      // User is signed in
      if (!this.currentUser) await this._sessionUser();
      this.triggerSessionChange(sessionState, { user: this.currentUser });
      return { data: { session: { user: this.currentUser } }, error: null }
    }

    return { data: { session: null }, error: null }
  }

  // Validate session state based on cookieExpires
  // This validates if their is a valid expiration cookie and if the session or refresh token is expired or is current. It doesn't validate if the session is valid from the descope server.
  /**
   * Validate session state based on cookieExpires
   * This validates if their is a valid expiration cookie and if the session or refresh token is expired or is current. It doesn't
   * validate if the session is valid from the descope server.
   * @return {string|null} The session state or null if invalid.
   */
  _validateSessionState() {
    const { cookieExpires: maybeExpired } = this._useSession();
    if (!maybeExpired) return null;
  
    // Check if cookie expires is valid cookie and return session state
    if (this._validCookieExpires(maybeExpired)) {
      const currentTime = Math.floor(new Date().getTime() / 1000);
      const { crx, csx } = maybeExpired;
      if (currentTime > crx) return REFRESH_EVENTS['REFRESH_EXPIRED']; 
      if (currentTime > csx) return SESSION_EVENTS['SESSION_EXPIRED'];
      return SESSION_EVENTS['SESSION_CURRENT'];
    };

    this._removeSessionCookies();
    return 'EVENT_INVALID';
  }

  /**
   * Checks if the given cookie expiration data is valid.
   *
   * @param {Object} maybeCookie - The cookie expiration data to validate.
   * @param {number} maybeCookie.crx - Refresh token expiration timestamp.
   * @param {number} maybeCookie.csx - Session expiration timestamp.
   * @return {boolean} True if the cookie data is valid, false otherwise.
   */
  _validCookieExpires(maybeCookie) {
    return maybeCookie && 'crx' in maybeCookie && 'csx' in maybeCookie;
  }

  /**
   * Fetches the current user session data.
   *
   * @return {Promise<Object>} A promise that resolves to the user session data.
   */
  async _sessionUser() {
    try {
      const { data: user, success } = await _fetch(`${this.authUrl}/me`, {
        method: 'GET',
        headers: this._createAuthorizationHeader(true),
      });
      // If user is not found, ensure any session cookies are removed
      if (!success) this._removeSessionCookies();
      this.currentUser = success ? user : null;
      return { user };
    } catch (error) {
      return _handleError(error);
    }
  }

  /**
 * Saves session-related cookies.
 *
 * @param {Object} data - The session data to save as cookies.
 * @param {string} [data.sessionJwt] - The session JWT.
 * @param {string} [data.refreshJwt] - The refresh JWT.
 * @param {number} [data.cookieExpiration] - The cookie expiration timestamp.
 */
  _saveSessionCookies(data) {
    if (data.sessionJwt) cookies.set(this.cookieSessionName, data.sessionJwt);
    if (data.refreshJwt) cookies.set(this.cookieRefreshName, data.refreshJwt);
    if (data.cookieExpiration) {
      cookies.set(this.cookieExpiresName, JSON.stringify({
        crx: data.cookieExpiration,
        csx: Math.floor((new Date().getTime() + 3600000) / 1000) // Session expires in 1 hour
      }));
    }
  }

  /**
   * Retrieves session-related cookies.
   *
   * @return {Object} An object containing session and expiration cookies.
   */
  _retrieveSessionCookies() {
    return {
      cs: cookies.get(this.cookieSessionName),
      cr: cookies.get(this.cookieRefreshName),
      cx: JSON.parse(cookies.get(this.cookieExpiresName))
    };
  }

  /**
   * Removes session-related cookies.
   */
  _removeSessionCookies() {
    cookies.delete(this.cookieSessionName);
    cookies.delete(this.cookieRefreshName);
    cookies.delete(this.cookieExpiresName);
  }

  /**
   * Retrieves session and user data from cookies.
   *
   * @return {Object} An object containing session, user, and expiration data.
   * @property {string} refreshToken - 
   * @property {string}  sessionToken - 
   * @property {Object} cookieExpires -
   * @property {Object} session -
   * @property {Object} user -
   */
  _useSession() {
    const { cs: sessionToken, cr: refreshToken, cx: cookieExpires } = this._retrieveSessionCookies();
    return {
      refreshToken,
      sessionToken,
      cookieExpires,
      session: this.currentSession,
      user: this.currentUser
    }
  }

  /**
   * Retrieves the current session token.
   *
   * @return {string|null} The current session token, or null if not available.
   */
  get token() {
    return this._useSession().sessionToken;
  }

  /**
   * Fetches a new session token using the refresh token.
   *
   * @return {Promise<Object>} A promise that resolves to the new session token data.
   */
  async _callSessionToken() {
    try {
      const { data } = await _fetch(`${this.authUrl}/refresh`, {
        headers: this._createAuthorizationHeader(true),
      });
      this._saveSessionCookies(data);
      return { data };
    } catch (error) {
      return _handleError(error);
    }
  }

  /**
   * Creates an authorization header for API requests.
   *
   * @param {boolean} [refresh=false] - Whether to include the refresh token in the header.
   * @return {Object} The authorization header.
   */
  _createAuthorizationHeader(refresh = false) {
    let bearer = this.projectId;
    const refreshToken = cookies.get(this.cookieRefreshName);
    if (refresh && refreshToken) bearer = `${this.projectId}:${refreshToken}`;
    return { 'Authorization': `Bearer ${bearer}` };
  }
}
