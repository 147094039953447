import { cookie } from "@common";
import { createRouter, createWebHistory } from "vue-router";

import { useExperiment } from "../composables/experiment";
// import { useLocalStorage } from "../composables/storage";
import { useAccount } from "../composables/api";
import { useTraits } from "../composables/traits";
import { routeNames } from "../constants";
import { useAuthStore } from "../store/auth.store";
import { useUserStore } from "../store/user.store";
import { accountRoutes } from "./account.routes";
import { authRoutes } from "./auth.routes";
import { demoRoutes } from "./demo.routes";
import { healthylivingRoutes } from "./healthyliving.routes";
import { interviewRoutes } from "./interview.routes";
import { legacyRoutes } from "./legacy.routes";
import { marketingRoutes } from "./marketing.routes";
// import { quickPriceRoutes } from "./quick-price.routes";
import { quoteRoutes } from "./quote.routes";
import { redirectRoutes } from "./redirect.routes";
import { webhooksRoutes } from "./webhooks.routes";

let routes = [
  ...marketingRoutes,
  ...authRoutes,
  ...interviewRoutes,
  ...redirectRoutes,
  ...demoRoutes,
  ...quoteRoutes,
  ...accountRoutes,
  ...healthylivingRoutes,
  ...webhooksRoutes,
  ...legacyRoutes,
];

const scrollPositions = {};

export function createAppRouter(app) {
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
      // Initial scroll disable on quick view for imo client
      if (to.meta.disableScroll) return;
      // if you're navigating to a route (or to the same route you're on) then this code below
      // will deal with jumping to the relevant html element. If you're going to the same
      // element as the current hash, then we don't offset as it seems to want to do it twice.
      if (to.hash) {
        return { el: to.hash, top: from.hash === to.hash ? 0 : 80 };
      }
      // this chunk of code will get the saved position from the push/pop history of the browser nav stack
      // and the will save it in the session so that we can retrieve it at a later date. we might want to
      // consider making it a plugin (or something) at some point.
      const scrollPos = savedPosition || { left: 0, top: 0 };
      if (savedPosition) {
        scrollPositions[to.path] = savedPosition;
      } else {
        delete scrollPositions[to.path];
      }
      window.sessionStorage.setItem("--df-router.scrollPositions", JSON.stringify(scrollPositions));
      return scrollPos;
    },
    routes,
  });

  router.beforeResolve((to) => {
    if (to.query && to.query["ko"]) {
      localStorage.setItem("--df.flag.ko", "true");
    }
  });

  router.beforeEach(async (to, from, next) => {
    const host = window.location.host;
    if (!host.includes("localhost")) {
      const terminalRoutes = ["home", "why-dayforward", "about", "faq", "q-slug"];
      if (host === "www.dayforward.com" && terminalRoutes.includes(to.name)) {
        window.location = `https://dayforward.io`;
      }
    }

    const authStore = useAuthStore();

    // added tp remove screen lock if back button is used to navigate while modal is open
    if (from.name === routeNames.INTERVIEW_E_SIGN) {
      const body = document.querySelector("body");
      if (body.classList.contains("hidden")) {
        body.classList.remove("hidden");
      }
    }

    // Check authentication if route requires authentication
    const { $descope } = app.config.globalProperties;
    if ($descope.token.value) {
      if ($descope.client.sessionExpired() || !$descope.session.value) {
        const {
          data: { session },
        } = await $descope.client.getSession();
        if (!session) authStore.setState("$authenticated", false);
        else authStore.setState("$authenticated", true);
      }
    }

    // if (authStore.authenticated) {
    //   const { queryAccount } = useAccount(app.config.globalProperties.$apollo);
    //   console.log("Query user", userStore.hasProp("$user"))
    //   const { payload } = await queryAccount()
    //   console.log(payload.value);
    // }
    // console.log("router.beforeEach", authStore.authenticated, to);
    // If route requires authentication and user is not authenticated, redirect to sign in
    if (to.meta.authentication && !authStore.authenticated) {
      next({ name: routeNames.AUTH_SIGN_IN });
      return;
    }
    // if (Object.keys(from.query).length && !Object.keys(to.query).length && from.name !== routeNames.AUTH_CALLBACK) {
    //   next({ name: to.name, params: { ...to.params },  query: { ...from.query } })
    // }
    next();
  });

  const { bucket, source } = useExperiment();
  const { traits } = useTraits();
  router.afterEach((to, from) => {
    const context = cookie("analytics:utm");
    source.value = to.query.utm_source ? to.query?.utm_source : context ? JSON.parse(context).source : null;
    if (!to.meta?.doNotTrack) {
      const analytics = app.config.globalProperties.$analytics;
      analytics?.page(
        {
          path: to.path,
          fullPath: to.fullPath,
        },
        {
          from: from.path,
          source: source.value,
          experiment: bucket.value,
          ...(traits.value && traits.value),
        },
      );
    }
  });

  return router;
}
